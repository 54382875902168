<script setup lang="ts">
import TagsList from './components/TagsList.vue'
import AddEditTag from './components/AddEditTag.vue'
import { useNotification, UIButton, UIHeader } from '@gohighlevel/ghl-ui'
import { PlusIcon } from '@gohighlevel/ghl-icons/24/outline'
import { ref } from 'vue'
import DeleteTags from './components/DeleteTags.vue'
import { IEventsResponse, ITagsBulkDeleteStatus } from '@/types'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const notification = useNotification()

const showConfirmDeleteModal = ref(false)
const selectedTagsIds = ref<string[]>([])
const selectedTagName = ref<string[]>([])
const tagListRef = ref()
const openAddEditTags = ref(false)

function onDelete(event: IEventsResponse[]) {
  showConfirmDeleteModal.value = true
  selectedTagsIds.value = event.map(o => o.id)
}

function onEdit(event: IEventsResponse[]) {
  selectedTagsIds.value = event.map(o => o.id)
  selectedTagName.value = event.map(o => o.name)
  openAddEditTags.value = true
}

function toggleAddEditTagModal() {
  openAddEditTags.value = !openAddEditTags.value
}

function onDeleteModalClose(status: ITagsBulkDeleteStatus) {
  showConfirmDeleteModal.value = false
  if (status !== undefined && 'succeeded' in status) {
    const message = status.succeeded
      ? `${
          selectedTagsIds.value.length === 1
            ? t('tags.tagDeleted')
            : t('tags.tagsDeleted')
        }`
      : t('tags.tagsDeleteError', {
          tag: `${selectedTagsIds.value.length === 1 ? 'Tag' : 'Tags'}`,
        })
    const notificationPayload = { title: message, duration: 10000 }
    status.succeeded
      ? notification.success(notificationPayload)
      : notification.error(notificationPayload)
    tagListRef.value.refreshTagsList()
  }
  selectedTagsIds.value = []
}

function onAddModalClose(status: ITagsBulkDeleteStatus) {
  openAddEditTags.value = false
  if (status !== undefined && 'succeeded' in status) {
    const message =
      status?.message &&
      status?.message.length &&
      status?.message?.includes('already exist')
        ? t('tags.duplicateTagError')
        : status.succeeded
        ? `${
            selectedTagName.value && selectedTagName.value.length
              ? t('tags.tagsUpdated', {
                  tag: selectedTagsIds.value.length === 1 ? 'Tag' : 'Tags',
                })
              : t('tags.tagsAdded', {
                  tag: selectedTagsIds.value.length === 1 ? 'Tag' : 'Tags',
                })
          } `
        : t('tags.tagsAddUpdateError', {
            tag: selectedTagsIds.value.length === 1 ? 'Tag' : 'Tags',
            operation: selectedTagName.value.length ? 'updating' : 'adding',
          })
    const notificationPayload = { title: message, duration: 10000 }
    status.succeeded
      ? notification.success(notificationPayload)
      : notification.error(notificationPayload)
    tagListRef.value.refreshTagsList()
  }
  selectedTagsIds.value = []
  selectedTagName.value = []
}
</script>

<template>
  <div class="mb-tags-list">
    <UIHeader
      id="tags-header"
      :title="$t('tags.tags')"
      :description="$t('common.desc')"
      :primaryText="$t('tags.newTag')"
      :primaryActionIcon="PlusIcon"
      @primaryClick="toggleAddEditTagModal"
    >
    </UIHeader>
    <TagsList
      @onDelete="onDelete"
      ref="tagListRef"
      @onEdit="onEdit"
      @onCreate="openAddEditTags = true"
    />
    <DeleteTags
      :showModal="showConfirmDeleteModal"
      :ids="selectedTagsIds"
      @onClose="onDeleteModalClose"
    />
    <AddEditTag
      :showModal="openAddEditTags"
      @onClose="onAddModalClose"
      v-bind:tagId="selectedTagsIds"
      v-bind:tagName="selectedTagName"
    />
  </div>
</template>

<style scoped>
.mb-tags-list {
  margin-bottom: 60px;
}
</style>
