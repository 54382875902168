export default class User {
    public static TYPE_AGENCY = "agency";
    public static TYPE_ACCOUNT = "account";
    public static ROLE_ADMIN = "admin";
    public static ROLE_USER = "user";
  
    data: any = {};
  
    constructor(data?: any) {
      this.data = data;
    }
  
    get id(): string {
      return this.data.id;
    }
  
    get firstName(): string {
      return this.data.first_name;
    }
  
    get lastName(): string {
      return this.data.last_name;
    }
  
    get title(): string {
      return this.data.title;
    }
  
    get fullName(): string {
      return [this.title, this.firstName, this.lastName].filter((d) => d).join(" ");
    }
  
    get phone(): string {
      return this.data.phone;
    }
  
    get email(): string {
      return this.data.email;
    }
  
    get initials(): string {
      let initials = "";
      if (this.firstName) initials += this.firstName.substring(0, 1).toUpperCase();
      if (this.lastName) initials += this.lastName.substring(0, 1).toUpperCase();
      return initials;
    }
  
    get profilePhoto(): string {
      return this.data.profile_photo
    }
  
    get profileColor(): string {
      let str = this.fullName;
      if (this.phone) str += this.phone;
      if (this.email) str += this.email;
      let hash = 0;
      if (str.length == 0) return "#afb8bc";
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
      }
  
      const shortened = Math.abs(hash) % 360;
      return "hsl(" + shortened + ",35%, 60%)";
    }
  }
  