

interface Config {
  mode: string;
  REST_API_URLS: string;
}

const config: { [key: string]: Config } = {
  development: {
    mode: "dev",
    REST_API_URLS: 'https://staging.services.leadconnectorhq.com',
  
  },
  staging: {
    mode: "staging",
    REST_API_URLS: 'https://staging.services.leadconnectorhq.com',
  
  },
  production: {
    mode: "production",
    REST_API_URLS: "https://services.leadconnectorhq.com",
   
  },
};

const override = false;

const overrideConfig = {
  override: true,
};
//@ts-ignore
const envConfig = config[process.env.NODE_ENV];

export default override ? { ...envConfig, ...overrideConfig } : envConfig;
