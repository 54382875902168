<script setup lang="ts">

import { Trash02Icon } from "@gohighlevel/ghl-icons/24/outline";
import { UIModal, UIModalFooter, UIModalHeader } from "@gohighlevel/ghl-ui";
import { toRefs } from "vue";
import { useRoute } from "vue-router";
import { useDeleteTags } from '../../../composables/useTags';


const props = defineProps<{ showModal: boolean, ids: string[] }>();
const emit = defineEmits(["onClose"]);

const route = useRoute();
const { tagsDelete, deleteTagsList, loading } = useDeleteTags()


const { ids, showModal } = toRefs(props);





function closeDeleteModal(succeeded?: boolean) {
    if (succeeded !== undefined) {
        emit("onClose", { succeeded });
    } else emit("onClose");

    showModal.value = false
}


function deleteTags() {
    let locationId = route.params?.locationId;
    const tagsDeletePayload = {
        tagIds: ids.value
    }
    if (Array.isArray(locationId)) {
        locationId = locationId[0];
    }
    if (locationId) {
        deleteTagsList(locationId, tagsDeletePayload).then(
            () => closeDeleteModal(tagsDelete.value?.succeded)
        )
    } else {
        closeDeleteModal()
    }

}


</script>

<template>
    <UIModal v-model:show="showModal" @update:show="(val) => $emit('onClose')">
        <template #header>
            <UIModalHeader type="error" :title="ids && ids.length > 1 ? $t('tags.deleteTags') : $t('tags.deleteTag')"
                :description="`${ids.length} ${ids && ids.length > 1 ? $t('tags.deleteTagsDescription') : $t('tags.deleteTagDescription')} `"
                :icon="Trash02Icon" @close="closeDeleteModal()" id="tags-delete-modal-header">
            </UIModalHeader>
        </template>

        <template #footer>
            <UIModalFooter :positiveText="$t('tags.tagDeletePositive')" :negativeText="$t('common.cancel')"
                @positive-click="deleteTags()" @negative-click="closeDeleteModal()" :loading="loading" id="modal-footer"
                type="error">

            </UIModalFooter>
        </template>
    </UIModal>
</template>
