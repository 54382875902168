import Postmate from "postmate";
import { defineStore } from "pinia";
import User from "@/models/user";

export interface AppStore {
  locationId: string;
  userEmail: string;
  userId: string;
  token: string;
  appHandshake: Postmate.Model | undefined;
  companyId: string;
  timezone: string;
  locale: string;
  appType: "STANDALONE" | "EMBED";
  requestRetryCount: number;
  company: { [key: string]: string | boolean } | null;
  users: { [key: string]: string | boolean } | null;
  user: User | null;
  calendarProviders: { [key: string]: string | boolean } | null;
  authorization : string;
}

export const useAppStore = defineStore("app", {
  state: (): AppStore => ({
    locationId: (import.meta.env.VITE_LOCATION_ID as string) || "",
    userId: (import.meta.env.VITE_USER_ID as string) || "",
    userEmail: (import.meta.env.VITE_USER_EMAIL as string) || "",
    token: (import.meta.env.VITE_TOKEN_ID as string) || "",
    appType: import.meta.env.VITE_APP_TYPE || "STANDALONE",
    companyId: "",
    timezone: "",
    locale: "en_US",
    appHandshake: undefined,
    requestRetryCount: 0,
    company: null,
    users: null,
    user: null,
    calendarProviders: null,
    authorization : ""
  }),
  actions: {
    updateRequestRetryCount(count: number) {
      this.requestRetryCount = count;
    },
    initialize(payload: AppStore) {
      this.locationId = payload.locationId;
      this.userId = payload.userId;
      this.userEmail = payload.userEmail;
      this.token = payload.token;
      this.companyId = payload.companyId;
      this.timezone = payload.timezone;
      this.locale = payload.locale;
      this.appType = payload.appType;
      this.company = payload.company;
      this.users = payload.users;
      // this.campaigns = payload.campaigns;
      this.user = new User(payload.user);
      // this.currentLocation = payload.currentLocation;
      // this.isV2SideBarEnable = payload.isV2SideBarEnable;
      this.calendarProviders = payload.calendarProviders;
      this.authorization  =  payload.authorization

    },
    refreshToken(newToken: string) {
      this.token = newToken;
    },
    abortDeletion(data: object) {
      console.log(data);
    },
    setAppHandshake(payload: any) {

      this.appHandshake = payload;
    },
  },
});
