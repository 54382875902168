<script setup lang="ts">
import { Pencil02Icon, Trash02Icon } from '@gohighlevel/ghl-icons/24/outline'
import { Tag01Icon, PlusIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIActionDropdown,
  renderIcon,
  UITable,
  UIEmpty,
  UIDropdown,
  UIButton,
} from '@gohighlevel/ghl-ui'
import { h, onBeforeMount, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { useTagsList } from '../../../composables/useTags'
import { useRoute } from 'vue-router'
import { IEventsResponse, ITagsListPayload, ITagsSetup } from '@/types'
import { debounce } from '@/composables/useDebounceRef'
import { useAppStore } from '@/store/app'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
const data = reactive({
  selectedTags: [],
  searchInput: '',
  pageSizes: [10, 50, 100],
  currentPageSize: 10,
  currentPage: 1,
})
const route = useRoute()
const { t } = useI18n()
const i18n = useI18n()

const { pageCount, tagsList, fetchTagsList, loading } = useTagsList()

const { searchInput, pageSizes, currentPageSize, currentPage, selectedTags } =
  toRefs<ITagsSetup>(data)

const appStore = useAppStore()

const emit = defineEmits(['onDelete', 'onEdit', 'onCreate'])

const DropdownActionOptions = ref()
const bulkOptions = ref()
const columnsHeading = ref()

watch(appStore, store => {
  if (store.token && store.token.length) refreshTagsList()
})

const init = () => {
  DropdownActionOptions.value = [
    {
      label: t('common.edit'),
      key: 'edit',
      disabled: selectedTags.value.length > 0,
      icon: renderIcon(Pencil02Icon),
    },
    {
      label: t('common.delete'),
      key: 'delete',
      disabled: selectedTags.value.length > 0,
      icon: renderIcon(Trash02Icon),
    },
  ]

  bulkOptions.value = [
    {
      label: t('tags.tagsBulkDelete'),
      key: 'delete',
      icon() {
        return h(renderIcon(Trash02Icon), null, {})
      },
    },
  ]

  columnsHeading.value = [
    {
      type: 'selection',
      key: '_id',
    },
    {
      title: t('tags.tags'),
      key: 'name',
    },
    {
      title: t('tags.createdOn'),
      key: 'dateAdded',
      render(row) {
        if (row && row?.dateAdded && row?.dateAdded.length) {
          return h('div', [
            h(
              'h5',
              {
                class: 'm-0 text-sm font-normal	',
              },
              moment(row.dateAdded).format('MMM DD YYYY')
            ),
            h(
              'h6',
              {
                class: 'm-0 text-xs font-normal text-gray-300',
              },
              moment(row.dateAdded).format('hh mm A')
            ),
          ])
        } else {
          return '-'
        }
      },
    },
    {
      title: t('tags.updatedOn'),
      key: 'dateUpdated',
      render(row) {
        if (row && row?.dateUpdated && row?.dateUpdated.length) {
          return h('div', [
            h(
              'h5',
              {
                class: 'm-0 text-sm font-normal	',
              },
              moment(row.dateUpdated).format('MMM DD YYYY')
            ),
            h(
              'h6',
              {
                class: 'm-0 text-xs font-normal text-gray-300',
              },
              moment(row.dateUpdated).format('hh mm A')
            ),
          ])
        } else {
          return '-'
        }
      },
    },

    {
      title: '',
      render(row) {
        return h(
          UIActionDropdown,
          {
            options: DropdownActionOptions.value,
            id: 'tbl-action-drop',
            onSelect: val => dropDownActionChange(val, row),
          },
          {}
        )
      },
    },
  ]
}

watch(i18n.locale, () => {
    init()
})

onBeforeMount(() => {
    init()
})

function restoreAll() {
  selectedTags.value = []
  currentPage.value = 1
  searchInput.value = ''
}

function refreshTagsList() {
  const tagsListPayload = {
    skip: 0,
    limit: currentPageSize.value,
    query: '',
    getCount: true,
  }
  restoreAll()
  fetchTagList(tagsListPayload)
}

function fetchTagList(payload: ITagsListPayload) {
  let locationId = route.params?.locationId
  if (Array.isArray(locationId)) {
    locationId = locationId[0]
  }
  if (locationId) {
    fetchTagsList(locationId, payload)
  }
}

function pageChange(pageNumber: number) {
  currentPage.value = pageNumber
  selectedTags.value = []
  const tagsListPayload = {
    skip: (currentPage.value - 1) * currentPageSize.value,
    limit: currentPageSize.value,
    query: '',
    getCount: true,
  }
  fetchTagList(tagsListPayload)
}

function pageSizeChange(selectedSize: number) {
  currentPage.value = 1
  searchInput.value = ''
  const tagsListPayload = {
    skip: 0,
    limit: selectedSize,
    query: '',
    getCount: true,
  }
  fetchTagList(tagsListPayload)
  currentPageSize.value = selectedSize
}

function dropDownActionChange(action, row) {
  const eventPayload = { id: row._id, name: row.name }
  searchInput.value = ''

  if (action === 'delete') {
    emit('onDelete', [eventPayload])
  } else emit('onEdit', [eventPayload])
}

function emptyStateDescription() {
  return searchInput.value.length
    ? t('tags.noTagSearchResultDescription', { searchInput: searchInput.value })
    : t('tags.noTagsDescription')
}

watch(searchInput, text => {
  const tagsListPayload = {
    skip: 0,
    limit: currentPageSize.value,
    query: data.searchInput,
    getCount: true,
  }
  debounce(() => fetchTagList(tagsListPayload), 500)
})

function searchInputChange(text: string) {
  searchInput.value = text
}

function selectedRows(tags: string[]) {
  selectedTags.value = []
  selectedTags.value.push(...tags)
}

function onBulkAction(action: string) {
  if (action === 'delete') {
    const ids: IEventsResponse[] = []
    selectedTags.value.forEach(o => ids.push({ id: o, name: '' }))
    emit('onDelete', ids)
  }
}

function onCreateTag() {
  emit('onCreate')
}

function getPageDetails() {
  const from = (currentPage.value - 1) * currentPageSize.value + 1
  const to =
    currentPage.value * currentPageSize.value > tagsList?.total
      ? tagsList?.total
      : currentPage.value * currentPageSize.value
  const total = tagsList.value?.total

  return t('tags.paginationDetails', { from, to, total })
}

defineExpose({ refreshTagsList })
</script>

<template>
  <div>
    <UITable
      :columns="columnsHeading"
      @update:searchText="searchInputChange"
      :searchText="searchInput"
      @update:checkedRowKeys="selectedRows"
      :rowKey="row => row._id"
      :searchInputPlaceholder="$t('tags.searchTags')"
      :data="tagsList?.tags"
      :pageCount="pageCount"
      @update:page="pageChange"
      @update:page-size="pageSizeChange"
      :page="currentPage"
      :enableTextSearch="true"
      id="tags-table"
      :pageSize="currentPageSize"
      :pageSizes="pageSizes"
      :showSizePicker="true"
      :loading="loading"
      :checkedRowKeys="selectedTags"
    >
      <template v-slot:filter-left>
        <UIDropdown
          v-if="selectedTags.length"
          class="tags-select"
          id="tags-bulk-options"
          :options="bulkOptions"
          :placeholder="$t('tags.bulkActions')"
          @select="onBulkAction"
        >
          <UIButton id="tags-bulk-action">{{
            $t('tags.bulkActions')
          }}</UIButton>
        </UIDropdown>

        <div v-if="selectedTags.length" class="tags-selection ml-2">
          {{ selectedTags.length }}
          {{
            selectedTags.length > 1
              ? $t('common.rowsSelected')
              : $t('common.rowSelected')
          }}
        </div>
      </template>

      <template v-slot:footer-left>
        <div v-if="tagsList?.total">
          {{ getPageDetails() }}
        </div>
      </template>

      <template
        :key="tagsList?.tags ? tagsList?.tags?.length : 0"
        v-if="!(tagsList?.tags && tagsList?.tags?.length)"
        v-slot:empty
      >
        <div class="text-center">
          <UIEmpty
            id="no-tags"
            :title="$t('tags.noTagsDescription')"
            :description="emptyStateDescription()"
            :positiveText="$t('tags.addNewTag')"
            @positiveClick="onCreateTag"
            :icon="Tag01Icon"
            :positive-icon="PlusIcon"
          >
          </UIEmpty>
        </div>
      </template>
    </UITable>
  </div>
</template>

<style scoped>
.tags-selection {
  white-space: nowrap;
}
</style>
