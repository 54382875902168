
import { requests } from ".";
import { ITagsAddPayload, ITagsAddResponse, ITagsBulkDeletePayload, ITagsListPayload, ITagsListResponse } from "@/types";
import envConfig from '../config/index'

const baseURL = envConfig.REST_API_URLS


export const TagsService =  {
  get : (locationId: string , query: ITagsListPayload ) => {
    const querystr = query && Object.keys(query).length ? '?' + new URLSearchParams(query).toString() : '' 
    return requests(baseURL).get<ITagsListResponse>(`locations/${locationId}/tags/search` + querystr);
  },

  delete: (locationId: string , params: ITagsBulkDeletePayload ) => {
    return requests(baseURL).post<ITagsListResponse>(`locations/${locationId}/tags/bulk/delete`,params)
  },
  add: (locationId: string , params: ITagsAddPayload ) => {
    return requests(baseURL).post<ITagsAddResponse>(`locations/${locationId}/tags`,params)
  },
  update: (locationId: string , tagId : string, params: ITagsAddPayload ) => {
    return requests(baseURL).put<ITagsAddResponse>(`locations/${locationId}/tags/${tagId}`,params)
  },
};

