import axios, { AxiosRequestConfig } from "axios";
import  {TagsService}  from "./tagsService";

import { useAppStore } from "@/store/app";
import config from "@/config";


const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

const getHeader = () => {
  return {
    channel: "APP",
    source: "WEB_USER",
    version: "2021-07-28",
  };
};

export const requests = (host?: string) => {
  const baseUrl = host || config.REST_API_URLS;
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: getHeader(),
  });

  axiosInstance.interceptors.request.use(async (requestConfig: AxiosRequestConfig) => {
    const appStore = useAppStore();
    try {
      if (requestConfig && requestConfig.headers ) {
        requestConfig.headers["token-id"] =   appStore?.token
      }
      
      if (requestConfig && requestConfig.headers){
        requestConfig.headers['Authorization'] = `Bearer ${appStore?.authorization}`
      }


    } catch (e) {}
    return requestConfig;
  });

  axiosInstance.interceptors.response.use(
    function (res) {
      return res;
    },
    async function axiosRetryInterceptor(err) {
      const appStore = useAppStore();

      console.log("error in response");
      console.log(err.config, err.response, err.response.status);
      if (err.config && err.response && err.response.status === 401) {
        const retryCount = appStore.requestRetryCount;

        if (appStore.appHandshake) {
          appStore.appHandshake.then((parent) => {
            parent.emit("refresh-token");
          });
        }

        if (retryCount >= 5) {
          appStore.updateRequestRetryCount(0);
          return Promise.reject(err);
        }
        await wait(2 ** retryCount * 100); // exponential backoff time. -> wait for token
        appStore.updateRequestRetryCount(retryCount + 1);
        return axiosInstance.request(err.config);
      }
      return Promise.reject(err);
    }
  );

  return axiosInstance;
};

export { TagsService };
