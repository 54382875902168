<script setup lang="ts">

import { useAddTags, useUpdateTags } from "@/composables/useTags.js";
import { UIModal, UIModalHeader, UIModalContent, UIForm, UIFormItem, UIInput, UIModalFooter } from "@gohighlevel/ghl-ui"
import { ref, toRefs, watch, onMounted, onUpdated } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from 'vue-i18n'



const props = defineProps<{ showModal: boolean, tagName: string[], tagId: string[] }>();
const { showModal, tagName, tagId } = toRefs(props);
const formValue = ref({ name: tagName?.value && tagName?.value.length ? tagName?.value[0] : undefined })
const { tagAdd, addTag, loading } = useAddTags()
const { tagUpdate, update, loading: isUpdating } = useUpdateTags()
const formRef = ref()
const route = useRoute();
const { t } = useI18n()


const rules = {
  name: {
    required: true,
    validator(_, value) {
      if (!value || (value && !value.trim().length)) {
        return new Error(t('tags.inputTagName'))
      }
      if (value.trim().length > 1000) {
        return new Error(t('tags.maxCharacterExceeded'))
      }
    },
    trigger: ['input', 'blur']
  }
}

const emit = defineEmits(["onClose"]);
watch(props, (newValue) => {
  tagName.value = newValue.tagName;
  tagId.value = newValue.tagId
  formValue.value.name = tagName?.value && tagName?.value.length ? tagName?.value[0] : undefined
});


function createTag() {
  let locationId = route.params?.locationId;
  const payload = {
    name: formValue?.value?.name?.trim()
  }
  if (Array.isArray(locationId)) {
    locationId = locationId[0];
  }
  if (locationId) {

    return addTag(locationId, payload)
  }

  return Promise.resolve()

}

function updateTag() {
  let locationId = route.params?.locationId;
  const payload = {
    name: formValue?.value?.name?.trim()
  }
  if (Array.isArray(locationId)) {
    locationId = locationId[0];
  }
  if (locationId && tagId?.value) {
    return update(locationId, tagId?.value[0], payload)
  }

  return Promise.resolve()

}


function onSubmit() {
  return formRef
    .value
    .getForm()
    .validate().then(() => {

      if (!tagId?.value?.length) {
        createTag()
          .then(() => closeModal(!!(tagAdd.value && tagAdd.value?.tag?.id && tagAdd.value?.tag?.id.length), tagAdd.value['message']))


      } else {
        updateTag()
          .then(() => closeModal(!!(tagUpdate.value?.tag?.id && tagUpdate.value?.tag?.id.length), tagUpdate.value['message']))
      }

    })
}


function closeModal(succeeded: boolean, message?: string) {
  if (succeeded !== undefined) emit("onClose", { succeeded, message });
  else emit("onClose")
  formValue.value.name = undefined
}



</script>

<template>
  <UIModal :show="showModal" id="add-edit-tags-modal" @update:show="(val) => $emit('onClose')">
    <template #header>
      <UIModalHeader id="modal-header" type="success"
        :title="`${tagName && tagName.length ? $t('tags.editTag') : $t('tags.addTag')}`" @close="closeModal">
      </UIModalHeader>
    </template>

    <UIModalContent>
      <UIForm :model="formValue" :rules="rules" ref="formRef" id="tag-modal-form">
        <UIFormItem :label="$t('common.name')" path="name">
          <UIInput v-model="formValue.name" :placeholder="$t('tags.tagName')" id="tag-modal-form-input" />
        </UIFormItem>

      </UIForm>

    </UIModalContent>

    <template #footer>
      <UIModalFooter :positiveText="`${tagName && tagName.length ? $t('common.update') : $t('common.create')}`"
        :negativeText="$t('common.cancel')" @positive-click="onSubmit" @negative-click="closeModal"
        :loading="loading || isUpdating" id="modal-footer">
      </UIModalFooter>
    </template>
  </UIModal>
</template>

<style scoped></style>
