import { ref, customRef } from 'vue'

let timeout
const debounce = (fn : Function, delay = 0) => {

    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn()
    }, delay)
}




const useDebouncedRef = (initialValue : string, delay : number, immediate : boolean ) => {
  const state = ref(initialValue)
  const debouncedRef = customRef((track, trigger) => ({
    get() {
      track()
      return state.value
    },
    set: debounce(
      value => {
        state.value = value
        trigger()
      },
      delay,
      immediate
    ),
  }))
  return debouncedRef
}

export  {useDebouncedRef , debounce}