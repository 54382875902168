import { Sleep } from "@/helpers";
import {TagsService} from "@/services/tagsService";
import { IAddTagDuplicateResponse, ITagsAddPayload, ITagsAddResponse, ITagsBulkDeletePayload, ITagsBulkDeleteResponse, ITagsListPayload, ITagsListResponse } from "@/types/index";
import { ref } from "vue";
import  { AxiosError } from "axios";


export const useTagsList = () => {
  const tagsList = ref<ITagsListResponse | any>({});
  const loading = ref(true);
  const pageCount = ref(0);

  const fetchTagsList = async (locationId: string, query : ITagsListPayload ) => {
 
    try {

      loading.value = true;
      const res = await TagsService.get(locationId,query);
    
      tagsList.value = res.data as ITagsListResponse;
      if ( res.data?.total) {
             pageCount.value = Math.ceil( res.data?.total / query.limit )
      }
    } catch {
        tagsList.value = {};
    } finally {
      loading.value = false;
    }
  };

  return {
    pageCount,
    tagsList,
    fetchTagsList,
    loading,
  };
};

export const useDeleteTags = () => {
    const tagsDelete = ref<ITagsBulkDeleteResponse | any>({});
    const loading = ref(false);

  
    const deleteTagsList = async (locationId: string, params : ITagsBulkDeletePayload ) => {
   
      try {
        loading.value = true;
        const res = await TagsService.delete(locationId,params);
        await Sleep(5000)
        tagsDelete.value = res.data;
      } catch {
        tagsDelete.value = {};
      } finally {
        loading.value = false;
      }
    };
  
    return {
        tagsDelete,
      deleteTagsList,
      loading,
    };
  };


  export const useAddTags = () => {
    const tagAdd = ref<ITagsAddResponse | any>({});
    const loading = ref(false);

  
    const addTag = async (locationId: string, params : ITagsAddPayload ) => {
   
      try {
        loading.value = true;
        const res = await TagsService.add(locationId,params);
        await Sleep(5000)
        tagAdd.value = res.data;
      } catch (e ) {
          const error = e  as AxiosError<IAddTagDuplicateResponse>   
          if(error?.response?.data?.message && error?.response?.data?.message.includes('The tag name is already exist.') )   tagAdd.value = error?.response?.data;
          else tagAdd.value = {}
      } finally {
        loading.value = false;
      }
    };
  
    return {
      tagAdd,
      addTag,
      loading,
    };
  };



  export const useUpdateTags = () => {
    const tagUpdate = ref<ITagsAddResponse | any>({});
    const loading = ref(false);

  
    const update = async (locationId: string, tagId: string  , params : ITagsAddPayload ) => {
   
      try {
        loading.value = true;
        const res = await TagsService.update(locationId,tagId,params);
        await Sleep(5000)
        tagUpdate.value = res.data;
      } catch(e) {
        const error = e  as AxiosError<IAddTagDuplicateResponse>   
        if(error?.response?.data?.message && error?.response?.data?.message.includes('already exists') )   tagUpdate.value = error?.response?.data;
        else tagUpdate.value = {};
      } finally {
        loading.value = false;
      }
    };
  
    return {
      tagUpdate,
      update,
      loading,
    };
  };