import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";


import TagsList from "@/pages/tags/Tags.vue";

const routes: Readonly<RouteRecordRaw[]> = [

  {
    path: "/v2/location/:locationId/settings/tags",
    component: TagsList,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export { router };
