import "@gohighlevel/ghl-ui/dist/style.css";
import messages from '@intlify/unplugin-vue-i18n/messages';
import * as Sentry from '@sentry/vue';
import { setupSentry } from 'common/util/setupSentry';
import { createPinia } from "pinia";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import { router } from "./router";
import "./style.css";

const app = createApp(App);

app.use(createPinia());
setupSentry(Sentry, app, router, process.env.NODE_ENV as string)
export const i18n = createI18n({
  locale: 'en_US',
  globalInjection: true,
  legacy: false,
  runtimeOnly: true,
  fallbackLocale: 'en_US',
  availableLocales: ["hr", "da", "nl", "en_US", "fi", "fr_CA", "fr_FR", "de", "hu", "no", "pt_BR", "pt_PO", "es", "sv"],
  messages: messages
})

app.use(i18n)

app.use(router);
app.mount("#app");
